import { Button, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import { Setting2, Verify } from "iconsax-react";
import { AppstoreOutlined } from "@ant-design/icons";
import "./style.css";
import { handleUpdateUser } from "../../Utils/UpdateUsersState";
import { UserContext } from "../../Utils/Context/userContext";

const Sidebar2 = ({ breakpoint = null }) => {
  const path = useLocation();
  const locationPath = path.pathname;
  const { userData, setUserData } = useContext(UserContext);

  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState("1");

  const items = [
    {
      key: "1",
      label: (
        <Link to="/general-settings">
          <Setting2
            size="20"
            color="#324054"
            variant={selectedId === "1" ? "Bold" : ""}
          />
          <span className="menu-item-label">General Settings</span>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/verifications">
          <Verify
            size="20"
            color="#324054"
            variant={selectedId === "2" ? "Bold" : ""}
          />
          <span className="menu-item-label">2 Steps Verification</span>
        </Link>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <Link to="/apis">
    //       <Link1
    //         size="20"
    //         color="#324054"
    //         variant={selectedId === "3" ? "Bold" : ""}
    //       />
    //       <span className="menu-item-label">API</span>
    //     </Link>
    //   ),
    // },
  ];

  useEffect(() => {
    if (locationPath.includes("/general-settings")) {
      setSelectedId("1");
    } else if (locationPath.includes("/verifications")) {
      setSelectedId("2");
    } else if (locationPath.includes("/apis")) {
      setSelectedId("3");
    } else {
      setSelectedId("1");
    }
  }, [locationPath]);

  const handleApplication = () => {
    handleUpdateUser(setUserData, { ...userData, settings: false });
    navigate("dashboard");
  };

  return (
    <Sider
      className="sidebar"
      width={250}
      theme="light"
      breakpoint={breakpoint}
      collapsedWidth="0"
      style={{ height: "100vh", position: "fixed" }}
    >
      <div>
        <div className="logo-container">
          <img
            width={120}
            style={{ padding: "5px" }}
            src={logo}
            alt="test-logo"
          />
        </div>
        <div style={{ height: "fit-content", overflowY: "scroll" }}>
          <Menu
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedId]}
            mode="inline"
            theme="dark"
            items={items}
          />
        </div>
      </div>
      <div className="sidebar-footer">
        <Button
          onClick={handleApplication}
          icon={<AppstoreOutlined />}
          type="primary"
        >
          Application
        </Button>
      </div>
    </Sider>
  );
};

export default Sidebar2;
