import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const adminUsers = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user/all/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const adminUser = async (user_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { user_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const editAdminUserPass = async (user_id, data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user/password/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { user_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const updateAdminUser = async (user_id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { user_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getAdminPlans = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/plan/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export {
  adminUsers,
  adminUser,
  editAdminUserPass,
  updateAdminUser,
  getAdminPlans,
};
