import React, { useEffect, useState } from "react";
import "./style.css";
import domain from "../../../Assets/Icons/global.png";
import email from "../../../Assets/Icons/direct.png";
import scan from "../../../Assets/Icons/scan.png";
import ai from "../../../Assets/Icons/illustrator.png";
import { CaretDownFilled } from "@ant-design/icons";
import { Collapse } from "antd";
import { getDashboardData } from "../../../API/Dashboard";
import { ClipLoader } from "react-spinners";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "white",
  borderRadius: 0,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getDashboardData();

      if (Math.floor(res?.status) / 100 === 2) {
        setDashboardData(res.data);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="loader-container"
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  return (
    <div className="dashboard">
      <div className="page-title">Stats</div>
      <div className="wrapper">
        <div className="row">
          <div className="box domain">
            <div className="left">
              <div className="box-title">
                <span>Total Domains</span>
              </div>
              <div className="box-value">
                <span>{dashboardData?.total_domains}</span>
              </div>
            </div>
            <div className="right">
              <img src={domain} alt="domain" />
            </div>
          </div>

          <div className="box email">
            <div className="left">
              <div className="box-title">
                <span>Number of Emails</span>
              </div>
              <div className="box-value">
                <span>{dashboardData?.total_emails}</span>
              </div>
            </div>
            <div className="right">
              <img src={email} alt="email" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="box scan">
            <div className="left">
              <div className="box-title">
                <span>Number of Scans</span>
              </div>
              <div className="box-value">
                <span>{dashboardData?.total_scans}</span>
              </div>
            </div>
            <div className="right">
              <img src={scan} alt="scan" />
            </div>
          </div>

          <div className="box ai">
            <div className="left">
              <div className="box-title">
                <span>Number of AI Prompts</span>
              </div>
              <div className="box-value">
                <span>{dashboardData?.prompts}</span>
              </div>
            </div>
            <div className="right">
              <img src={ai} alt="AI" />
            </div>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      {dashboardData?.domains?.length > 0 && (
        <>
          <div className="page-title">Domains</div>
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretDownFilled rotate={isActive ? 180 : 0} />
            )}
            expandIconPosition="end"
          >
            {dashboardData.domains.map((domain, index) => (
              <Panel header={domain.name} key={index} style={customPanelStyle}>
                <div className="analytics">
                  <div className="analytic">
                    <div className="anal-title">
                      <span>Number of Records</span>
                    </div>
                    <div className="anal-value">
                      <span>{domain.num_records}</span>
                    </div>
                  </div>

                  <div className="analytic">
                    <div className="anal-title">
                      <span>Number of Recommendations</span>
                    </div>
                    <div className="anal-value">
                      <span>{domain.num_recommendations}</span>
                    </div>
                  </div>

                  <div className="analytic">
                    <div className="anal-title">
                      <span>Number of Changes</span>
                    </div>
                    <div className="anal-value">
                      <span>{domain.num_changes}</span>
                    </div>
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
        </>
      )}

      <div className="separator"></div>

      {dashboardData?.emails?.length > 0 && (
        <>
          <div className="page-title">Emails</div>
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretDownFilled rotate={isActive ? 180 : 0} />
            )}
            expandIconPosition="end"
          >
            {dashboardData.emails.map((email, index) => (
              <Panel header={email.name} key={index} style={customPanelStyle}>
                <div className="analytics">
                  <div className="analytic">
                    <div className="anal-title">
                      <span>Number of Records</span>
                    </div>
                    <div className="anal-value">
                      <span>{email.num_records}</span>
                    </div>
                  </div>

                  <div className="analytic">
                    <div className="anal-title">
                      <span>Number of Recommendations</span>
                    </div>
                    <div className="anal-value">
                      <span>{email.num_recommendations}</span>
                    </div>
                  </div>

                  <div className="analytic">
                    <div className="anal-title">
                      <span>Number of Changes</span>
                    </div>
                    <div className="anal-value">
                      <span>{email.num_changes}</span>
                    </div>
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
        </>
      )}
    </div>
  );
};

export default Dashboard;
