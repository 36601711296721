import { Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import { editDomain, moveDomainsBulk } from "../../../../API/Domain";
import { getFolders } from "../../../../API/Folder";
import { ClipLoader } from "react-spinners";

const BulkMoveModal = ({
  folderId,
  visible,
  setVisible,
  ids,
  fetchData,
  email,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState([]);

  const fetchFolders = async () => {
    setLoading(true);
    const res = await getFolders();

    if (Math.floor(res?.status / 100) === 2) {
      setFolders(res.data);
    } else {
      message.error("Folder Fetch failed");
    }
  };

  const getName = (folderId) => {
    if (email) {
      return folders?.email?.filter((folder) => folder.id === folderId);
    }
    return folders?.domain?.filter((folder) => folder.id === folderId);
  };

  useEffect(() => {
    const func = async () => {
      await fetchFolders();
    };
    func();
  }, [form, visible]);

  useMemo(() => {
    const name = getName(parseInt(folderId))?.[0];

    form.setFieldsValue({
      folderId: name?.id,
    });
    setLoading(false);
  }, [folders]);

  const handleBulkMoveDomains = async (folder_id) => {
    setLoading(true);
    try {
      const res = await moveDomainsBulk(folder_id, ids);
      if (Math.floor(res?.status / 100) === 2) {
        fetchData(email);
      } else {
        message.error(`Failed to move ${email ? "emails" : "domains"}.`);
      }
    } catch (error) {
      console.error("Error moving:", error);
      message.error(`Error moving ${email ? "email" : "domain"}.`);
    }
    setVisible(false);
    setLoading(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        handleBulkMoveDomains(values?.folderId);
        message.success(`${email ? "Emails" : "Domains"} moved successfully`);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      className="domain-modal"
      title={`Move ${email ? "Emails" : "Domains"}`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Move"
      okButtonProps={{ loading: loading }}
    >
      {loading ? (
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      ) : (
        <Form
          loading={loading}
          form={form}
          layout="vertical"
          name="editDomainForm"
        >
          <Form.Item
            name="folderId"
            label="Folder"
            rules={[{ required: true, message: "Please select a folder!" }]}
          >
            <Select placeholder="Select folder">
              {email
                ? folders?.email?.map((folder) => (
                    <Select.Option key={folder.id} value={folder.id}>
                      {folder.name}
                    </Select.Option>
                  ))
                : folders?.domain?.map((folder) => (
                    <Select.Option key={folder.id} value={folder.id}>
                      {folder.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default BulkMoveModal;
