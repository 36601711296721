import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const getDashboardData = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/dashboard/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { getDashboardData };
