import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popconfirm,
  message,
} from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import {
  AddCircle,
  Direct,
  Eye,
  Folder,
  Global,
  StatusUp,
} from "iconsax-react";
import { SettingOutlined, WarningOutlined } from "@ant-design/icons";
import "./style.css";
import {
  createFolder,
  deleteFolder,
  editFolder,
  getFolders,
} from "../../API/Folder";
import { UserContext } from "../../Utils/Context/userContext";
import { handleUpdateUser } from "../../Utils/UpdateUsersState";

const Sidebar = ({ breakpoint = null }) => {
  const path = useLocation();
  const locationPath = path.pathname;

  const navigate = useNavigate();

  const { userData, setUserData } = useContext(UserContext);
  const [selectedId, setSelectedId] = useState("1");
  const [selectedKey, setSelectedKey] = useState("1");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [emailBool, setEmailBool] = useState(false);
  const [contextMenuProps, setContextMenuProps] = useState({
    visible: false,
    key: null,
    position: { x: 0, y: 0 },
  });
  const [folders, setFolders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editFolderId, setEditFolderId] = useState("");
  const [editFolderName, setEditFolderName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getFolders();

      if (res && res.data) {
        setFolders(res.data);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSelectedKeyChange = (key) => {
    setSelectedKey(key);
  };

  const addFolderModal = (value) => {
    if (value === "domain") {
      setEmailBool(false);
    } else {
      setEmailBool(true);
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    handleCreateFolder();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSettings = () => {
    handleUpdateUser(setUserData, { ...userData, settings: true });
    navigate("/general-settings");
  };

  useEffect(() => {
    const handleClick = () => {
      setContextMenuProps({
        visible: false,
        key: 0,
        position: { x: -100, y: -100 },
      });
    };

    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleRightClick = (event, key, id) => {
    setEditFolderId(id);

    if (key[0] === "2") {
      setEmailBool(false);
    } else {
      setEmailBool(true);
    }
    event.preventDefault();
    setContextMenuProps({
      visible: true,
      key,
      position: { x: event.clientX, y: event.clientY },
    });
  };

  const handleClick = (e, key, id) => {
    if (e.type === "contextmenu") {
      handleRightClick(e, key, id);
    } else if (e.type === "click") {
      handleSelectedKeyChange(key);
    }
  };

  const handleMenuClick = (e) => {
    const { key } = e;
    if (key === "edit") {
      handleEditFolder(contextMenuProps.key);
    }
    setContextMenuProps({ ...contextMenuProps, visible: false });
  };

  const handleEditFolder = (key) => {
    const folder =
      folders.domain.find((f) => f.key === key) ||
      folders.email.find((e) => e.key === key);
    if (folder) {
      setEditFolderName(folder.name);
      setIsEditModalVisible(true);
    }
  };

  const handleEditFolderSubmit = async () => {
    try {
      const res = await editFolder(editFolderId, editFolderName, emailBool);
      if (Math.floor(res?.status / 100) === 2) {
        message.success("Folder updated successfully!");
        setIsEditModalVisible(false);
        setEditFolderName("");

        setFolders(res?.data);
      } else {
        message.error("Failed to update folder.");
      }
    } catch (error) {
      console.error("Error updating folder:", error);
      message.error("Error updating folder.");
    }
  };

  const handleDeleteFolder = async () => {
    try {
      const res = await deleteFolder(editFolderId);
      if (Math.floor(res?.status) / 100 === 2) {
        message.success("Folder deleted successfully!");

        setFolders(res?.data);
      } else {
        message.error("Failed to delete folder.");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      message.error("Error deleting folder.");
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Popconfirm
        onConfirm={handleDeleteFolder}
        title="Delete the Folder"
        description="This will also delete all the domains in this folder!"
        icon={
          <WarningOutlined
            style={{
              color: "red",
            }}
          />
        }
      >
        <Menu.Item key="delete">Delete</Menu.Item>
      </Popconfirm>
    </Menu>
  );

  const domainItems =
    folders?.domain?.map((domain) => ({
      key: domain.key,
      label: (
        <Dropdown
          overlay={menu}
          trigger={["contextMenu"]}
          visible={
            contextMenuProps.visible && contextMenuProps.key === domain?.key
          }
          onClick={(e) => handleClick(e, domain?.key, domain?.id)}
          overlayStyle={{
            position: "absolute",
            left: contextMenuProps.position.x,
            top: contextMenuProps.position.y,
          }}
        >
          <div
            onContextMenu={(e) => handleClick(e, domain?.key, domain?.id)}
            className="email-item"
          >
            <Link to={`/domains/${domain?.key}`}>
              <Folder
                size="20"
                color="#324054"
                variant={selectedId === domain?.key ? "Bold" : ""}
              />
              <span className="menu-item-label">{domain?.name}</span>
            </Link>
          </div>
        </Dropdown>
      ),
    })) || [];

  const emailItems =
    folders?.email?.map((email) => ({
      key: email.key,
      label: (
        <Dropdown
          overlay={menu}
          trigger={["contextMenu"]}
          visible={
            contextMenuProps.visible && contextMenuProps.key === email.key
          }
          onClick={(e) => handleClick(e, email?.key, email?.id)}
          overlayStyle={{
            position: "absolute",
            left: contextMenuProps.position.x,
            top: contextMenuProps.position.y,
          }}
        >
          <div
            onContextMenu={(e) => handleClick(e, email?.key, email?.id)}
            className="email-item"
          >
            <Link to={`/emails/${email.key}`}>
              <Folder
                size="20"
                color="#324054"
                variant={selectedId === email.key ? "Bold" : ""}
              />
              <span className="menu-item-label">{email.name}</span>
            </Link>
          </div>
        </Dropdown>
      ),
    })) || [];

  const items = [
    {
      key: "1",
      label: (
        <Link to="/lookup">
          <Eye
            size="20"
            color="#324054"
            variant={selectedId === "1" ? "Bold" : ""}
          />
          <span className="menu-item-label">Lookup</span>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/stats">
          <StatusUp
            size="20"
            color="#324054"
            variant={selectedId === "2" ? "Bold" : ""}
          />
          <span className="menu-item-label">Stats</span>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/domains">
          <Global size="20" color="#324054" />
          <span className="menu-item-label">Domains</span>
        </Link>
      ),
      children: [
        ...domainItems,
        {
          key: "3-100",
          label: (
            <Link onClick={() => addFolderModal("domain")}>
              <AddCircle size="20" color="#324054" variant="Bold" />
              <span className="menu-item-label" style={{ fontWeight: 600 }}>
                Add Folder
              </span>
            </Link>
          ),
        },
      ],
    },
    {
      key: "4",
      label: (
        <Link to="/emails">
          <Direct size="20" color="#324054" />
          <span className="menu-item-label">Contacts</span>
        </Link>
      ),
      children: [
        ...emailItems,
        {
          key: "4-100",
          label: (
            <Link onClick={() => addFolderModal("email")}>
              <AddCircle size="20" color="#324054" variant="Bold" />
              <span className="menu-item-label" style={{ fontWeight: 600 }}>
                Add Folder
              </span>
            </Link>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    if (locationPath.includes("/stats")) {
      setSelectedId("2");
    } else if (locationPath.includes("/lookup")) {
      setSelectedId("1");
    } else {
      setSelectedId(selectedKey);
    }
  }, [locationPath, selectedKey]);

  const handleCreateFolder = async () => {
    setLoading(true);
    try {
      const res = await createFolder(folderName, emailBool);
      if (Math.floor(res?.status / 100) === 2) {
        message.success("Folder created successfully!");
        setIsModalVisible(false);
        setFolderName("");
        setEmailBool(false);

        setFolders(res?.data);
      } else {
        message.error("Failed to create folder.");
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      message.error("Error creating folder.");
    }
    setLoading(false);
  };

  return (
    <Sider
      className="sidebar"
      width={250}
      theme="light"
      breakpoint={breakpoint}
      collapsedWidth="0"
      style={{ height: "100vh", position: "fixed" }}
    >
      <div>
        <div className="logo-container">
          <img
            width={120}
            style={{ padding: "5px" }}
            src={logo}
            alt="test-logo"
          />
        </div>

        <div style={{ height: "fit-content", overflowY: "scroll" }}>
          <Menu
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedId]}
            mode="inline"
            theme="dark"
            items={items}
          />
        </div>
      </div>
      <div className="sidebar-footer">
        <Button
          onClick={handleSettings}
          icon={<SettingOutlined />}
          style={{ boxShadow: "none" }}
          type="primary"
        >
          Settings
        </Button>
      </div>

      <Modal
        title="Add Folder"
        open={isModalVisible}
        onOk={() => handleOk()}
        onCancel={handleCancel}
        centered
      >
        <Input
          placeholder="Enter folder name"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
      </Modal>

      <Modal
        title="Edit Folder"
        open={isEditModalVisible}
        onOk={handleEditFolderSubmit}
        onCancel={() => setIsEditModalVisible(false)}
        centered
      >
        <Input
          placeholder="Enter folder name"
          value={editFolderName}
          onChange={(e) => setEditFolderName(e.target.value)}
        />
      </Modal>
    </Sider>
  );
};

export default Sidebar;
