import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Button, Input, Popconfirm, Space, Table, message } from "antd";
import {
  PlusOutlined,
  ReloadOutlined,
  ScanOutlined,
  TruckOutlined,
} from "@ant-design/icons";
import { ArrowSquareRight, Edit2, Trash } from "iconsax-react";
import { Link, useParams } from "react-router-dom";
import AddDomainModal from "./DomainModal/AddDomainModal";
import EditDomainModal from "./DomainModal/EditDomainModal";
import { deleteDomain, getDomains } from "../../../API/Domain";
import { ClipLoader } from "react-spinners";
import favicon from "../../../Assets/Images/favicon.ico";
import { rescanDomain } from "../../../API/Record";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import BulkMoveModal from "./DomainModal/BulkMoveModal";

const Domains = () => {
  const [domains, setDomains] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [bulkModalVisible, setBulkModalVisible] = useState(false);
  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [editDomainName, setEditDomainName] = useState("");
  const [editDomainId, setEditDomainId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [email, setEmail] = useState(false);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const { domainType } = useParams();
  const id = domainType.split("-")[1];

  const [loading, setLoading] = useState(true);

  const fallbackImage = (event) => {
    event.target.src = favicon;
  };

  const fetchData = async (email) => {
    setSelectedRowKeys([]);
    setLoading(true);
    const res = await getDomains(id, email);

    if (Math.floor(res?.status) / 100 === 2) {
      setDomains(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (window.location.pathname.includes("email")) {
      setEmail(true);
      fetchData(true);
    } else {
      setEmail(false);
      fetchData(false);
    }
  }, [id]);

  const handleEdit = (record) => {
    setEditDomainName(record?.name);
    setEditDomainId(record?.id);

    setIsVisibleEdit(true);
  };

  const handleDelete = async (record) => {
    try {
      const res = await deleteDomain([record?.id]);
      if (Math.floor(res?.status) / 100 === 2) {
        message.success(`${email ? "Email" : "Domain"} deleted successfully!`);

        setDomains(res?.data);
      } else {
        message.error("Failed to delete.");
      }
    } catch (error) {
      console.error("Error deleting:", error);
      message.error("Error deleting.");
    }
    setSelectedRowKeys([]);
  };

  const handleDeleteBulk = async () => {
    try {
      const res = await deleteDomain(selectedRowKeys);
      if (Math.floor(res?.status) / 100 === 2) {
        message.success(`${email ? "Email" : "Domain"} deleted successfully!`);

        setDomains(res?.data);
      } else {
        message.error("Failed to delete domain.");
      }
    } catch (error) {
      console.error("Error deleting:", error);
      message.error("Error deleting.");
    }
    setSelectedRowKeys([]);
  };

  const handleBulkRescan = async () => {
    setLoading(true);
    try {
      const res = await rescanDomain(selectedRowKeys);
      if (res && Math.floor(res?.status / 100) === 2) {
        message.success("Rescan successfully Started!");
      } else {
        message.error("Rescan failed!");
      }
    } catch (e) {
      message.error("An error occurred during the rescan!");
      console.log(e);
    }
    setLoading(false);
    setSelectedRowKeys([]);
  };

  const showBulkMoveModal = () => {
    setBulkModalVisible(true);
  };

  const showDomainModal = () => {
    setIsVisible(true);
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="loader-container"
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    fetchData(email);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#50a5f1" : "#50a5f1",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <div className="domains">
      <div className="domain-wrapper">
        <div className="page-title">
          <span>{email ? "Emails" : "Domains"}</span>
          <div style={{ display: "flex", gap: "10px" }}>
            {selectedRowKeys?.length > 0 ? (
              <>
                <span
                  style={{ fontSize: "24px", display: "flex", gap: "25px" }}
                >
                  <Popconfirm
                    title={`Delete ${email ? "Email" : "Domain"}`}
                    description={`Are you sure you want to delete these ${
                      email ? "emails" : "domains"
                    }?`}
                    onConfirm={() => handleDeleteBulk()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Link>
                      <Trash variant="Bold" color="#FF4D4F" />
                    </Link>
                  </Popconfirm>
                </span>
                <Link onClick={handleBulkRescan}>
                  <ScanOutlined />
                </Link>
                <Link onClick={showBulkMoveModal}>
                  <TruckOutlined />
                </Link>
              </>
            ) : null}
          </div>
          <div className="buttons">
            <Button
              onClick={showDomainModal}
              style={{ boxShadow: "none" }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Add {email ? "Email" : "Domain"}
            </Button>

            <Button
              type="secondary"
              onClick={() => fetchData(email)}
              icon={<ReloadOutlined />}
            >
              Refresh
            </Button>
          </div>
        </div>

        <div className="table-container">
          <Table
            pagination={{ position: ["bottomCenter"] }}
            dataSource={domains}
            rowSelection={rowSelection}
            rowKey="id"
          >
            <Table.Column
              width={40}
              title=""
              dataIndex="icon"
              key="icon"
              render={(text, record, index) => (
                <>
                  <img
                    src={text ? text : favicon}
                    onError={fallbackImage}
                    alt="Favicon"
                    style={{ width: "30px", height: "30px" }}
                  />
                </>
              )}
            />
            <Table.Column
              title={`${email ? "Email" : "Domain"} Name`}
              dataIndex="name"
              key="name"
              render={(name) => <span className="domain-name">{name}</span>}
              {...getColumnSearchProps("name")}
            />
            <Table.Column
              title="Email Gateway"
              dataIndex="email_gateway"
              key="email_gateway"
            />
            <Table.Column
              title="URL"
              dataIndex="url"
              key="url"
              render={(url) => (
                <div onClick={() => window.open("http://" + url, "_blank")}>
                  <a>{url}</a>
                </div>
              )}
            />

            <Table.Column
              title="Records"
              dataIndex="records"
              key="records"
              sorter={(a, b) => a.records - b.records}
            />
            <Table.Column title="Status" dataIndex="status" key="status" />
            <Table.Column
              width={120}
              title="Scan Date"
              dataIndex="scan_date"
              key="scan_date"
            />
            <Table.Column
              width={140}
              title="Actions"
              key="actions"
              render={(text, record, index) => (
                <span
                  style={{ fontSize: "24px", display: "flex", gap: "25px" }}
                >
                  <Link onClick={() => handleEdit(record)}>
                    <Edit2 color="#FFC107" />
                  </Link>
                  <Popconfirm
                    title={`Delete ${email ? "Email" : "Domain"}`}
                    description="Are you sure you want to delete?"
                    onConfirm={() => handleDelete(record)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Link>
                      <Trash variant="Bold" color="#FF4D4F" />
                    </Link>
                  </Popconfirm>
                  <Link
                    to={
                      email
                        ? `/email-details/${domainType}/${record.id}`
                        : `/domain-details/${domainType}/${record.id}`
                    }
                  >
                    <ArrowSquareRight variant="Bold" color="#073F73" />
                  </Link>
                </span>
              )}
            />
          </Table>
        </div>
      </div>
      <AddDomainModal
        folderId={id}
        visible={isVisible}
        setVisible={setIsVisible}
        setDomains={setDomains}
        email={email}
      />
      {isVisibleEdit ? (
        <EditDomainModal
          folderId={id}
          visible={isVisibleEdit}
          setVisible={setIsVisibleEdit}
          domainName={editDomainName}
          id={editDomainId}
          setDomains={setDomains}
          email={email}
        />
      ) : null}
      <BulkMoveModal
        folderId={id}
        visible={bulkModalVisible}
        setVisible={setBulkModalVisible}
        ids={selectedRowKeys}
        fetchData={fetchData}
        email={email}
      />
    </div>
  );
};

export default Domains;
