import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const getFolders = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/folder/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const createFolder = async (folderName, emailBool) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/folder/`,
      {
        name: folderName,
        email: emailBool,
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const editFolder = async (id, folderName, emailBool) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/folder/`,
      {
        name: folderName,
        email: emailBool,
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const deleteFolder = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/folder/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { getFolders, createFolder, editFolder, deleteFolder };
