import React from "react";
import Users from "../Pages/Admin/Users/Users";
import Subscriptions from "../Pages/Admin/Subscriptions/Subscriptions";
import Subscription from "../Pages/Admin/Subscription/Subscription";
import { Navigate, Route, Routes } from "react-router-dom";
import User from "../Pages/Admin/User/User";
import AddNewSub from "../Pages/Admin/AddNewSub/AddNewSub";
import VerifyNow from "../Pages/Public/VerifyNow/VerifyNow";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/users" element={<Users />} />
      <Route path="/user/:userId" element={<User />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/subscription/:subId" element={<Subscription />} />
      <Route path="/add-new-subscription" element={<AddNewSub />} />
      <Route path="/verify/:uuid" element={<VerifyNow />} />
      <Route path="*" element={<Navigate to="/users" />} />
    </Routes>
  );
};

export default AdminRoutes;
