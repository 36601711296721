import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import PublicRoutes from "./PublicRoutes";
import AppLayout from "../Components/AppLayout";
import { UserContext } from "../Utils/Context/userContext";
import Verification from "../Pages/User/Verification/Verification";
import CheckEmail from "../Pages/Public/CheckEmail/CheckEmail";
import VerifyNow from "../Pages/Public/VerifyNow/VerifyNow";

const AppRoutes = ({ mode, setMode }) => {
  const { userData } = useContext(UserContext);
  let routesComponent;

  switch (userData?.role) {
    case "admin":
      if (userData?.fa) {
        routesComponent = (
          <Routes>
            <Route path="/verification" element={<Verification />} />
            <Route path="/verify/:uuid" element={<VerifyNow />} />
            <Route path="/*" element={<Navigate to="/verification" />} />
          </Routes>
        );
      } else {
        routesComponent = <AppLayout contents={<AdminRoutes />} />;
      }
      break;
    case "user":
      if (userData?.fa) {
        routesComponent = (
          <Routes>
            <Route path="/verification" element={<Verification />} />
            <Route path="/verify/:uuid" element={<VerifyNow />} />
            <Route path="/*" element={<Navigate to="/verification" />} />
          </Routes>
        );
      } else {
        if (!userData?.verified) {
          routesComponent = (
            <Routes>
              <Route path="/check-email" element={<CheckEmail />} />
              <Route path="/verify/:uuid" element={<VerifyNow />} />
              <Route path="/*" element={<Navigate to="/check-email" />} />
            </Routes>
          );
        } else {
          routesComponent = (
            <AppLayout
              contents={<UserRoutes mode={mode} setMode={setMode} />}
            />
          );
        }
      }
      break;
    default:
      routesComponent = <PublicRoutes setMode={setMode} />;
      break;
  }

  return <BrowserRouter>{routesComponent}</BrowserRouter>;
};

export default AppRoutes;
