import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import { Eye, Home2 } from "iconsax-react";

import "./style.css";

const SidebarAdmin = ({ breakpoint = null }) => {
  const path = useLocation();
  const locationPath = path.pathname;

  const [selectedId, setSelectedId] = useState("1");
  const items = [
    {
      key: "1",
      label: (
        <Link to="/users">
          <Home2
            size="20"
            color="#324054"
            variant={selectedId === "1" ? "Bold" : ""}
          />
          <span className="menu-item-label">Users</span>
        </Link>
      ),
    },

    {
      key: "2",
      label: (
        <Link to="/subscriptions">
          <Eye
            size="20"
            color="#324054"
            variant={selectedId === "2" ? "Bold" : ""}
          />
          <span className="menu-item-label">Subscriptions</span>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (locationPath.includes("user")) {
      setSelectedId("1");
    } else if (locationPath.includes("subscription")) {
      setSelectedId("2");
    } else {
      setSelectedId("1");
    }
  }, [locationPath]);

  return (
    <Sider
      className="sidebar"
      width={250}
      theme="light"
      breakpoint={breakpoint}
      collapsedWidth="0"
      style={{ height: "100vh", position: "fixed" }}
    >
      <div>
        <div className="logo-container">
          <img
            width={120}
            style={{ padding: "5px" }}
            src={logo}
            alt="test-logo"
          />
        </div>
        <div style={{ height: "fit-content", overflowY: "scroll" }}>
          <Menu
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedId]}
            mode="inline"
            theme="dark"
            items={items}
          />
        </div>
      </div>
      <div className="sidebar-footer"></div>
    </Sider>
  );
};

export default SidebarAdmin;
