import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const register = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/register/`,
      data
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const login = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/token/`,
      data
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const refresh = async (refresh) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/token/refresh/`,
      { refresh },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const verify2fa = async (otp) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/verify-2fa/`,
      { otp },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const get2faStatus = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/2fa-status/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const disable2fa = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/disable-2fa/`,
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const forgotPassword = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/forgot-password/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const enable2fa = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/enable-2fa/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const resetPassword = async (data, param) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/reset-password/${param}/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getVerification = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/verification/resend/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const verifyUser = async (param) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/verify/${param}/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export {
  register,
  login,
  refresh,
  verify2fa,
  get2faStatus,
  disable2fa,
  enable2fa,
  forgotPassword,
  resetPassword,
  getVerification,
  verifyUser,
};
