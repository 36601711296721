import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const getRecordsData = async (domain_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/record/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { domain_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const rescanDomain = async (domain_ids) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/rescan/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { domain_id: JSON.stringify(domain_ids) },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const getOverview = async (domain_id) => {
  console.log(domain_id);
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/overview/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { domain_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { getRecordsData, rescanDomain, getOverview };
