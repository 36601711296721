import { Button, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ArrowCircleDown, Edit2 } from "iconsax-react";
import React from "react";
import "./style.css";

const { Option } = Select;

const Subscription = () => {
  return (
    <div className="subs">
      <div className="section-title">General Information</div>

      <div className="section">
        <Form
          initialValues={{
            plan: "Basic",
            status: "active",
            price: "$9.19",
            details: "Some details",
          }}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item label="Package Name" name="plan">
            <Input addonAfter={<Edit2 size="16" color="black" />} />
          </Form.Item>

          <Form.Item label="Package Status" name="staus">
            <Select
              showSearch
              suffixIcon={
                <ArrowCircleDown size="16" color="black" variant="Bold" />
              }
              placeholder="Select a status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 200 }}
            >
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Price" name="price">
            <Input
              addonAfter={<Edit2 size="16" color="black" />}
              type="email"
            />
          </Form.Item>

          <Form.Item label="Package Details" name="details">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </div>
      <div className="section-title">Package Information</div>
      <div className="section">
        <Form
          initialValues={{ domains: 100, emails: 80, scans: 40, aiPrompts: 56 }}
          layout="vertical"
          requiredMark={false}
        >
          <div className="items-wrap">
            <div className="item">
              <Form.Item label="Number of Domains" name="domains">
                <Input addonAfter={<Edit2 size="16" color="black" />} />
              </Form.Item>
            </div>
            <div className="item">
              <Form.Item label="Number of Emails" name="emails">
                <Input addonAfter={<Edit2 size="16" color="black" />} />
              </Form.Item>
            </div>
          </div>
          <div className="items-wrap">
            <div className="item">
              <Form.Item label="Numnber of scans" name="scans">
                <Input addonAfter={<Edit2 size="16" color="black" />} />
              </Form.Item>
            </div>
            <div className="item">
              <Form.Item label="Number of AI-Prompts" name="aiPrompts">
                <Input addonAfter={<Edit2 size="16" color="black" />} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <div className="btn">
        <Button danger ghost>
          Delete Package
        </Button>
        <Button type="primary">Save Changes</Button>
      </div>
    </div>
  );
};

export default Subscription;
