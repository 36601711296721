import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const getUser = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const editUser = async (data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/user/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const changePassword = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/password/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { getUser, editUser, changePassword };
