import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const getLookup = async (domain) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/lookup/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { domain },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { getLookup };
