import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/User/Dashboard/Dashboard";
import Domains from "../Pages/User/Domains/Domains";
import Lookup from "../Pages/User/Lookup/Lookup";

import Settings from "../Pages/User/Settings/Settings";
import Verifications from "../Pages/User/Verifications/Verifications";
import APIs from "../Pages/User/APIs/API";
import Domain from "../Pages/User/Domain/Domain";

import VerifyNow from "../Pages/Public/VerifyNow/VerifyNow";

const UserRoutes = ({ mode, setMode }) => {
  return (
    <Routes>
      <Route path="/stats" element={<Dashboard />} />
      <Route path="/domains/:domainType" element={<Domains />} />
      <Route
        path="/domain-details/:domainType/:domainId"
        element={<Domain />}
      />
      <Route path="/emails/:domainType" element={<Domains />} />
      <Route path="/email-details/:domainType/:domainId" element={<Domain />} />
      <Route path="/lookup" element={<Lookup />} />
      <Route
        path="/general-settings"
        element={<Settings mode={mode} setMode={setMode} />}
      />
      <Route path="/verifications" element={<Verifications />} />
      <Route path="/apis" element={<APIs />} />
      <Route path="/verify/:uuid" element={<VerifyNow />} />
      <Route path="*" element={<Navigate to="/lookup" />} />
    </Routes>
  );
};

export default UserRoutes;
