import { Button, Modal, Tabs, message } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useState } from "react";
import Search from "antd/es/transfer/search";

import "./style.css";
import { getLookup } from "../../../API/Lookup";
import { ClipLoader } from "react-spinners";
import Overview from "../Domain/Overview";
import Records from "../Domain/Records";
import Debug from "../Domain/Debug";

const Lookup = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState([45.839859, -119.700577]);
  const [recordsData, setRecordsData] = useState([]);
  const [overviewData, setOverviewData] = useState(null);
  const [searchDomain, setSearchDomain] = useState("");

  const getLookupData = async (name) => {
    setLoading(true);
    try {
      const res = await getLookup(name);
      if (Math.floor(res?.status / 100) === 2) {
        setRecordsData(res?.data?.records);
        setOverviewData(res?.data);
        if (
          res?.data?.latitude === "Not Found" &&
          res?.data?.longitude === "Not Found"
        ) {
          console.warn("Latitude or longitude data is missing");
        } else {
          setPosition([res.data.latitude, res.data.longitude]);
        }
      } else {
        message.error("Fetch failed!");
      }
    } catch (e) {
      message.error("An error occurred during the rescan!");
      console.log(e);
    }
    setLoading(false);
  };

  const onSearch = (value) => {
    getLookupData(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="lookup">
      <div className="page-header">
        <div className="left">
          <span className="page-title">Lookup</span>
        </div>
        <div className="right">
          <Search
            onChange={(e) => setSearchDomain(e.target.value)}
            placeholder="Enter Domain/Email"
            onSearch={onSearch}
          />

          <Button onClick={() => onSearch(searchDomain)} type="primary">
            Search
          </Button>
          <Button onClick={showModal} className="debug-btn">
            Debug
          </Button>
        </div>
      </div>

      {overviewData ? (
        <div className="tabs-container">
          <Tabs defaultActiveKey="1" size="small">
            <TabPane key="1" tab="Overview">
              <Overview
                overviewData={overviewData}
                position={position}
                loading={loading}
              />
            </TabPane>
            <TabPane key="2" tab="Records">
              <Records recordsData={recordsData} loading={loading} />
            </TabPane>
          </Tabs>
        </div>
      ) : loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="loader-container"
        >
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <div className="search-placeholder">Search for a domain</div>
      )}

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-debug"
        centered
      >
        <Debug />
      </Modal>
    </div>
  );
};

export default Lookup;
