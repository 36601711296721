import axios from "axios";
import { getToken } from "../../Utils/UpdateUsersState";

const getDomains = async (folder, email) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/domain/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { folder, email },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const createDomain = async (folderId, domainName, domainUrl, emailBool) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/domain/`,
      {
        folder: folderId,
        name: domainName,
        domain: domainUrl,
        email: emailBool,
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const editDomain = async (id, domainName, folderId, emailBool) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/domain/`,
      {
        name: domainName,
        folder: folderId,
        email: emailBool,
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const deleteDomain = async (ids) => {
  console.log(ids);
  const token = getToken();
  console.log(token);
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/domain/`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { id: JSON.stringify(ids) },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const bulkUploadDomains = async (formData, email, folder_id) => {
  console.log(formData.get("file"));
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/bulk/domain/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
        params: { email, folder_id },
      }
    );
    return res;
  } catch (e) {
    return e;
  }
};

const exportRecords = async (domain_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/export/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { domain_id },
        responseType: "blob",
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const moveDomainsBulk = async (folderId, domainIds) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/move/domain/`,
      {
        folder_id: folderId,
        domain_id: domainIds,
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export {
  getDomains,
  createDomain,
  editDomain,
  deleteDomain,
  exportRecords,
  bulkUploadDomains,
  moveDomainsBulk,
};
