import { Button, Tabs, message } from "antd";
import {
  ExportOutlined,
  ScanOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import TabPane from "antd/es/tabs/TabPane";
import Overview from "./Overview/index";
import Records from "./Records/index";
import Recommendations from "./Recommendations/index";
import Debug from "./Debug/index";
import { useParams } from "react-router-dom";
import "./style.css";
import { getOverview, rescanDomain } from "../../../API/Record";
import { exportRecords } from "../../../API/Domain";

const Domain = () => {
  const { domainId } = useParams();
  const [loading, setLoading] = useState(false);

  const [recordsData, setRecordsData] = useState([]);
  const [overviewData, setOverviewData] = useState({});
  const [position, setPosition] = useState([45.839859, -119.700577]);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getOverview(domainId);
      if (Math.floor(res?.status / 100) === 2) {
        setRecordsData(res?.data?.records);
        await setOverviewData(res?.data);
        if (
          res?.data?.latitude === "Not Found" &&
          res?.data?.longitude === "Not Found"
        ) {
          console.warn("Latitude or longitude data is missing");
        } else {
          setPosition([res.data.latitude, res.data.longitude]);
        }
      } else {
        message.error("Fetch failed!");
      }
    } catch (e) {
      message.error("An error occurred during the rescan!");
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [domainId]);

  const handleRescan = async () => {
    setLoading(true);
    getData();
    try {
      const res = await rescanDomain([domainId]);
      if (res && Math.floor(res?.status / 100) === 2) {
        message.success("Rescan successfully Started!");
      } else {
        message.error("Rescan failed!");
      }
    } catch (e) {
      message.error("An error occurred during the rescan!");
      console.log(e);
    }
    setLoading(false);
  };

  const handleExportRecord = async () => {
    setLoading(true);
    try {
      const res = await exportRecords(domainId);
      if (Math.floor(res?.status / 100) === 2) {
        const blob = new Blob([res.data], {
          type: "text/csv",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "exported_file.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        message.success("File Exported Successfully");
      } else {
        message.error("Export failed!");
      }
    } catch (e) {
      message.error("An error occurred during the export!");
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div className="domain-details">
      <div className="page-header">
        <div className="left">
          <span className="domain-name">{overviewData?.name}: </span>
          <span className="domain-url">{overviewData?.domain}</span>
        </div>
        <div className="right">
          <Button
            onClick={handleRescan}
            className="rescan-button"
            icon={<ScanOutlined />}
            loading={loading}
          >
            Perform Rescan
          </Button>
          <Button
            onClick={handleExportRecord}
            icon={<ExportOutlined />}
            loading={loading}
            type="primary"
          >
            Export Record
          </Button>
        </div>
      </div>
      <div className="page-header-2">
        <Button onClick={getData} icon={<ReloadOutlined />}>
          Refresh
        </Button>
      </div>

      <div className="tabs-container">
        <Tabs defaultActiveKey="1" size="small">
          <TabPane key={1} tab="Overview">
            <Overview
              overviewData={overviewData}
              position={position}
              loading={loading}
            />
          </TabPane>
          <TabPane key={2} tab="Records">
            <Records recordsData={recordsData} loading={loading} />
          </TabPane>
          <TabPane key={3} tab="Recommendations">
            <Recommendations />
          </TabPane>
          <TabPane key={4} tab="Debug">
            <Debug />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Domain;
