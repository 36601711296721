import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ReactCountryFlag from "react-country-flag";
import "./style.css";

import { Collapse } from "antd";
import {
  MailOutlined,
  SafetyCertificateOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ClipLoader } from "react-spinners";
import { getCode } from "country-list";
import favicon from "../../../../Assets/Images/favicon.ico";

const { Panel } = Collapse;

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const Overview = ({ loading, overviewData, position }) => {
  const fallbackImage = (event) => {
    event.target.src = favicon;
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="loader-container"
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  return (
    <div className="overview">
      <div className="header">
        <div className="domain-info">
          <span className="domain-url">{overviewData?.domain}</span>

          <p>
            <strong>Email Gateway:</strong> {overviewData?.email_gateway}
          </p>
          <p>
            <strong>Last Scan Date:</strong> {overviewData?.scan_date}
          </p>
        </div>
        <div className="img-wrap">
          <img
            src={overviewData?.icon ? overviewData?.icon : favicon}
            onError={fallbackImage}
            alt={`${overviewData?.domain} favicon`}
            className="favicon"
          />
          <span className="domain-name">{overviewData?.name}</span>

          <span>
            <b>Status: </b>
            {overviewData?.status}
          </span>
        </div>
      </div>

      <div className="details">
        <Collapse accordion>
          <Panel header="MX Records" key="1" extra={<MailOutlined />}>
            <ul>
              {overviewData?.MX?.map((record, index) =>
                record.trim() !== "" ? (
                  <li className="list-item" key={index}>
                    {record}
                  </li>
                ) : null
              )}
            </ul>
          </Panel>
          <Panel header="SPF Record" key="2" extra={<CheckCircleOutlined />}>
            <ul>
              {overviewData?.SPF?.map((record, index) =>
                record.trim() !== "" ? (
                  <li className="list-item" key={index}>
                    {record}
                  </li>
                ) : null
              )}
            </ul>
          </Panel>
          <Panel header="DKIM Record" key="3" extra={<CheckCircleOutlined />}>
            <ul>
              {overviewData?.DKIM?.map((record, index) =>
                record.trim() !== "" ? (
                  <li className="list-item" key={index}>
                    {record}
                  </li>
                ) : null
              )}
            </ul>
          </Panel>
          <Panel
            header="DMARC Record"
            key="4"
            extra={<SafetyCertificateOutlined />}
          >
            <ul>
              {overviewData?.DMARC?.map((record, index) =>
                record.trim() !== "" ? (
                  <li className="list-item" key={index}>
                    {record}
                  </li>
                ) : null
              )}
            </ul>
          </Panel>
          <Panel
            header="Technology In DNS"
            key="5"
            extra={<CheckCircleOutlined />}
          >
            <ul>
              {overviewData?.tech_in_dns?.map((record, index) =>
                record.trim() !== "" ? (
                  <li className="list-item" key={index}>
                    {record}
                  </li>
                ) : null
              )}
            </ul>
          </Panel>
          <Panel
            header="Additional DNS Information"
            key="6"
            extra={<InfoCircleOutlined />}
          >
            <ul>
              {overviewData?.additional?.map((record, index) =>
                record.trim() !== "" ? (
                  <li className="list-item" key={index}>
                    {record}
                  </li>
                ) : null
              )}
            </ul>
          </Panel>
        </Collapse>
      </div>

      <div className="location">
        <div className="header">
          <div className="domain-info">
            <p>
              <strong>City:</strong> {overviewData?.city}
            </p>
            <p>
              <strong>Region:</strong> {overviewData?.region}
            </p>
            <p>
              <strong>Country:</strong> {overviewData?.country}
            </p>
          </div>
          {overviewData?.country && (
            <ReactCountryFlag
              countryCode={getCode(overviewData?.country) || ""}
              svg
              style={{ width: "100px", height: "auto" }}
            />
          )}
        </div>

        <div className="map">
          <MapContainer
            center={position}
            zoom={13}
            style={{ height: "300px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>
                {overviewData?.city}, {overviewData?.region}
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default Overview;
