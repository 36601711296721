import { Button, Form, Input, message, Progress, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { ArrowCircleDown, Edit2 } from "iconsax-react";
import {
  adminUser,
  editAdminUserPass,
  getAdminPlans,
  updateAdminUser,
} from "../../../API/Admin";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

const User = () => {
  const [data, setData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const params = useParams();
  const [form] = useForm();

  const fetchUser = async () => {
    setLoading(true);
    const res = await adminUser(params?.userId);
    if (Math.floor(res?.status / 100) === 2) {
      setData(res?.data);
    } else {
      message.error("Error fetching user");
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAdminPlans();
      setPlans(
        res?.data?.map((item) => {
          return { label: item?.title, value: item?.id };
        })
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
    };
    fetchData();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const updatePassword = async (values) => {
    const res = await editAdminUserPass(params?.userId, values);
    if (Math.floor(res?.status / 100) === 2) {
      form.resetFields();
      message.success("Password updated sucessfully!");
    } else {
      message.error("Error fetching user!");
    }
  };

  const updateUser = async (values) => {
    setLoading(true);
    const res = await updateAdminUser(params?.userId, values);
    if (Math.floor(res?.status / 100) === 2) {
      message.success("User updated successfully!");
      await fetchUser();
    } else {
      message.error("Error fetching user");
    }
    setLoading(false);
  };

  return (
    <div className="user">
      <div className="page-title">
        <span>User Information</span>
      </div>
      <div className="tabs-container">
        <div className="tab" style={{ borderLeftColor: "#073F73" }}>
          <span className="tab-title">Domains</span>
          <span className="tab-val">
            {data?.total_domains}/{data?.out_domains}
          </span>
          <Progress
            percent={(data?.total_domains / data?.out_domains) * 100}
            size="small"
          />
        </div>
        <div className="tab" style={{ borderLeftColor: "#0F5129" }}>
          <span className="tab-title">Emails</span>
          <span className="tab-val">
            {data?.total_emails}/{data?.out_emails}
          </span>
          <Progress
            percent={(data?.total_emails / data?.out_emails) * 100}
            size="small"
          />
        </div>
        <div className="tab" style={{ borderLeftColor: "#750E21" }}>
          <span className="tab-title">Scans</span>
          <span className="tab-val">
            {data?.total_scans}/{data?.out_scans}
          </span>
          <Progress
            percent={(data?.total_scans / data?.out_scans) * 100}
            size="small"
          />
        </div>
        <div className="tab" style={{ borderLeftColor: "#4A3A99" }}>
          <span className="tab-title">AI-Prompts</span>
          <span className="tab-val">
            {data?.total_prompts}/{data?.out_prompts}
          </span>
          <Progress
            percent={(data?.total_prompts / data?.out_prompts) * 100}
            size="small"
          />
        </div>
      </div>
      <div className="section">
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="loader-container"
          >
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
          </div>
        ) : (
          <Form
            initialValues={data?.user}
            layout="vertical"
            requiredMark={false}
            onFinish={updateUser}
          >
            <Form.Item label="User Name" name="name">
              <Input addonAfter={<Edit2 size="16" color="black" />} />
            </Form.Item>
            <Form.Item label="User Email" name="email">
              <Input
                addonAfter={<Edit2 size="16" color="black" />}
                type="email"
              />
            </Form.Item>
            <Form.Item label="Subscription Plan" name="plan">
              <Select
                showSearch
                suffixIcon={
                  <ArrowCircleDown size="16" color="black" variant="Bold" />
                }
                placeholder="Select a plan"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: 200 }}
                options={plans}
              ></Select>
            </Form.Item>
            {/* <Form.Item label="Subscription Details" name="details">
              <TextArea rows={4} />
            </Form.Item> */}
            <div className="btn">
              <Button danger type="primary">
                Revoke Subscription
              </Button>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
      <div className="section-title">Change Password</div>
      <div className="section">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={updatePassword}
          form={form}
        >
          <Form.Item label="New Password" name="new-password">
            <Input.Password
              className="password-input"
              required={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
          </Form.Item>

          <Form.Item
            label="Re-enter Password"
            name="new_pass"
            rules={[
              {
                required: true,
                message: "Please re-enter the Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new-password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="password-input"
              prefix={<LockOutlined className="site-form-item-icon" />}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
          </Form.Item>
          <div className="btn">
            <Button danger ghost>
              Delete Account
            </Button>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loading}
                loading={loading}
              >
                Change Password
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default User;
