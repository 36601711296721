import React, { useEffect, useState } from "react";
import "./style.css";
import { message, Radio, Popover, Button } from "antd";
import QrModal from "./VerifyModal/QrModal";
import { disable2fa, get2faStatus } from "../../../API/Auth";
import { ClipLoader } from "react-spinners";

const Verifications = () => {
  const [verificationMethod, setVerificationMethod] = useState("disable");
  const [isVisible, setIsVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false); // State for Popover visibility
  const [disableLoading, setDisableLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const formData = {
    description1:
      "The basic idea is that a single password for your important accounts simply isn’t enough. If your password is guessed, or hackers steal a database with your login information in plain text, your account is a sitting duck. Two-factor authentication tries to address that flaw by requiring a secondary code called a one-time password (OTP)—usually six characters in length and generated by a smartphone app—before you can gain access to your account. That way even if a hacker has your password they’ll still need to crack a secondary code, which makes getting in that much harder.",
  };

  const handleVerificationChange = async (e) => {
    if (e.target.value === "disable") {
      if (verificationMethod !== "disable") {
        setPopoverVisible(true);
      }
    } else {
      if (verificationMethod !== "enable") {
        setIsVisible(true);
      }
    }
  };

  const handleDisableConfirm = async () => {
    setDisableLoading(true);
    const res = await disable2fa();
    if (Math.floor(res?.status / 100) === 2) {
      message.success(res?.data?.detail);
      setVerificationMethod("disable");
    } else {
      message.error("Unable to change status!");
    }
    setPopoverVisible(false);
    setDisableLoading(false);
  };

  const handlePopoverClose = () => {
    setPopoverVisible(false);
  };

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const res = await get2faStatus();
      if (Math.floor(res?.status / 100) === 2) {
        if (res?.data?.fa_status === true) {
          setVerificationMethod("enable");
        } else {
          setVerificationMethod("disable");
        }
      } else {
        setVerificationMethod("disable");
      }
      setLoading(false);
    };
    func();
  }, []);

  const popoverContent = (
    <div>
      <p>
        Are you sure you want to disable 2FA? It might make your account
        unsecure.
      </p>
      <Button
        type="primary"
        onClick={handleDisableConfirm}
        loading={disableLoading}
      >
        Yes, disable
      </Button>
      <Button onClick={handlePopoverClose} style={{ marginLeft: 8 }}>
        Cancel
      </Button>
    </div>
  );

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="loader-container"
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  return (
    <div className="verifications">
      <div className="page-title">
        <span>2 Step Verification</span>
        <span
          className={`status-title ${
            verificationMethod === "enable"
              ? "status-enabled"
              : "status-disabled"
          }`}
        >
          {verificationMethod === "enable" ? "Enabled" : "Disabled"}
        </span>
      </div>
      <div className="verifications-section">
        <div className="form-item">
          <label className="form-label">Two-Factor Authentication (2FA)</label>
          <div className="form-item-text textarea1" readOnly>
            {formData.description1}
          </div>
        </div>

        <div className="form-item">
          <label className="form-label">
            Two-Factor Authentication (2FA) is not setup
          </label>
          <div className="form-item-text textarea1">
            <div className="form-item-text-two">
              Your current verification method:
              <span
                className={
                  verificationMethod === "enable"
                    ? "status-enabled-inline"
                    : "status-disabled-inline"
                }
              >
                {verificationMethod === "enable" ? " Enabled" : " Disabled"}
              </span>
            </div>
            <div className="form-item-text-blue">
              Email based Multi-Factor Authentication (MFA) is available for
              paid accounts
            </div>
            <Radio.Group
              onChange={handleVerificationChange}
              value={verificationMethod}
              className="radio-group"
            >
              <Radio className="fontcolor" value="enable">
                Enable
              </Radio>
              <Popover
                content={popoverContent}
                title="Confirm Disable"
                trigger="click"
                visible={popoverVisible}
              >
                <Radio className="fontcolor" value="disable">
                  Disable
                </Radio>
              </Popover>
            </Radio.Group>
          </div>
        </div>
      </div>
      <QrModal
        visible={isVisible}
        setVisible={setIsVisible}
        setVerificationMethod={setVerificationMethod}
      />
    </div>
  );
};

export default Verifications;
