import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, message } from "antd";
import "./style.css";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import { UserContext } from "../../../Utils/Context/userContext";
import { verify2fa } from "../../../API/Auth";

const Verification = () => {
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);
  const { setUserData, userData, setUserToken } = useContext(UserContext);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (value.length > 1) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const onFinish = async () => {
    const code = verificationCode.join("");
    const res = await verify2fa(code);
    if (Math.floor(res?.status / 100) === 2) {
      message.success(res?.data?.detail);
      handleUpdateUser(setUserData, { ...userData, fa: false });
    } else {
      message?.error(res?.response?.data?.detail);
    }
  };

  const logout = () => {
    try {
      handleLogout(setUserData, setUserToken);
      message.success("Logged Out!");
    } catch (e) {
      message.success("Error while Logging out");
    }
  };

  return (
    <div className="verification">
      <div className="verification-card">
        <div className="title">
          <h1>Verification</h1>
          <p>Please Enter Authentication Code</p>
        </div>
        <div className="form">
          <Form
            name="normal_verification"
            className="verification-form"
            requiredMark={false}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="verificationCode"
              rules={[
                {
                  message: "Please input the verification code!",
                  required: true,
                },
              ]}
            >
              <div className="verification-code-input">
                {Array.from({ length: 6 }).map((_, index) => (
                  <Input
                    key={index}
                    ref={(el) => (inputsRef.current[index] = el)}
                    maxLength={1}
                    onChange={(event) => handleInputChange(index, event)}
                    onKeyDown={(event) => handleKeyDown(index, event)}
                    className="verification-code-input-item"
                  />
                ))}
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="verification-form-button"
              >
                Verify
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            className="verification-form-button"
            onClick={logout}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Verification;
