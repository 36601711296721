import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Button, Form, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { verifyUser } from "../../../API/Auth";
import { handleLogout } from "../../../Utils/UpdateUsersState";
import { UserContext } from "../../../Utils/Context/userContext";

const VerifyNow = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { setUserData, setUserToken } = useContext(UserContext);

  useEffect(() => {
    handleLogout(setUserData, setUserToken);
  }, []);

  const onFinish = async () => {
    setLoading(true);
    const res = await verifyUser(params?.uuid);
    if (Math.floor(res?.status / 100) === 2) {
      message.success("Email Verified!");
      goToLogin();
    } else {
      message?.error("Could not Verify Email!");
    }
    setLoading(false);
  };

  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="login">
      <div className="login-card">
        <div className="title">
          <h1>Verify Your Email</h1>
          <p>Welcome to Kyla AI</p>
        </div>
        <div className="form">
          <Form
            name="normal_login"
            className="login-form"
            requiredMark={false}
            onFinish={onFinish}
            {...formLayout}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loading}
                loading={loading}
              >
                Verify Now
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <span className="signup-link">Already have an account? </span>
              <span onClick={goToLogin} className="signup-link underline">
                Login
              </span>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default VerifyNow;
