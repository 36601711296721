import { Collapse } from "antd";
import React from "react";
import { CaretDownFilled } from "@ant-design/icons";
import "./style.css";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "white",
  borderRadius: 0,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

const Recommendations = () => {
  const problemsAndSolutions = [
    {
      problem: "No MX Records Found",
      solution:
        "Check the DNS settings of the domain to ensure that MX records are properly configured. Verify the MX record entries in the DNS zone file and make sure they are correct.",
    },
    {
      problem: "No DMARC Record Found",
      solution:
        "Create and publish a DMARC record for the domain. A DMARC policy helps to protect email senders and recipients from spam, spoofing, and phishing attacks by defining how email messages should be handled if they fail authentication checks.",
    },
    {
      problem: "No SPF Record Found",
      solution:
        "Implement an SPF record for the domain to specify which mail servers are authorized to send emails on behalf of the domain. This helps to prevent email spoofing and improves email deliverability.",
    },
    {
      problem: "No DKIM Record Found",
      solution:
        "Set up DKIM for the domain to add a digital signature to outgoing emails. DKIM helps email receivers verify the authenticity of emails and detect tampering during transit.",
    },
    {
      problem: "Mail Server is Blacklisted",
      solution:
        "Investigate why the mail server's IP address is blacklisted and take appropriate actions to resolve the issue. This may involve identifying and addressing the source of spam or abuse originating from the mail server, improving security measures, and requesting delisting from blacklists.",
    },
    {
      problem: "Reverse DNS Does Not Match SMTP Banner",
      solution:
        "Configure the reverse DNS (PTR record) of the mail server to match the hostname specified in the SMTP banner. Consistent rDNS and SMTP banner information help improve email deliverability and reduce the likelihood of being flagged as spam.",
    },
  ];

  return (
    <div className="recommendations">
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretDownFilled rotate={isActive ? 180 : 0} />
        )}
        expandIconPosition="end"
      >
        {problemsAndSolutions.map((item, index) => (
          <Panel
            header={`Problem ${index + 1}: ${item.problem}`}
            key={index + 1}
            style={customPanelStyle}
          >
            <p className="sol">{item.solution}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Recommendations;
