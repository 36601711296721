import { Collapse, Input, Button } from "antd";
import { CaretDownFilled, LockFilled, CopyOutlined } from "@ant-design/icons";
import React, {useRef} from "react";
import "./style.css";

const { Panel } = Collapse;

const customPanelStyle = {
  background: "white",
  borderRadius: 0,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};



const API = () => {
  const inputRef = useRef(null);

  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand("copy");
  };

  const methodsandProcedures = [
    {
      method: "Lookup",
      procedure:
        "Check the DNS settings of the domain to ensure that MX records are properly configured. Verify the MX record entries in the DNS zone file and make sure they are correct.",
    },
    {
      method: "Monitor",
      procedure:
        "Create and publish a DMARC record for the domain. A DMARC policy helps to protect email senders and recipients from spam, spoofing, and phishing attacks by defining how email messages should be handled if they fail authentication checks.",
    },
    {
      method: "Monitor Tags",
      procedure:
        "Implement an SPF record for the domain to specify which mail servers are authorized to send emails on behalf of the domain. This helps to prevent email spoofing and improves email deliverability.",
    },
    {
      method: "Usage",
      procedure:
        "Set up DKIM for the domain to add a digital signature to outgoing emails. DKIM helps email receivers verify the authenticity of emails and detect tampering during transit.",
    },
  ];

  return (
    <div className="api">
      <div className="title">
        <span>API</span>
      </div>
      <div className="description">
        <span>
          The Kyla Ai API is a RESTful Web Service allowing Kyla AI customers
          to query the status of their monitors and run lookups (blacklist,
          smtp, mx, etc.). Generally, programmers use this API to integrate
          Kyla AI into their products. For example, customers have created a
          dashboard on their site which shows the real-time status of their
          monitors.
        </span>
      </div>
      <div className="key">
        <div className="key-input">
          <Input
            prefix={<LockFilled />}
            value="b41e71ed-6e42-455a-a0a0-821d86f160ec"
            readOnly
            className="api-key-input"
            ref={inputRef}
            addonAfter={<CopyOutlined onClick={copyToClipboard}/>}
          />
        </div>
        <div className="key-button">
          <Button type ="primary" danger>Remove API Key</Button>
        </div>
      </div>
      <div className="usage">
        <div className="usage-label">
          <label>API Usage</label>
          <div className="text-field">
            <span><b>Dns Requests Remaining Today:</b> 62 of 64</span>
            <span><b>Network Requests Remaining Today :</b> 0 of 0</span>
            <Button type="primary" className="request-button">Request an increase to API Limits</Button>
          </div>
        </div>
      </div>
      <div className="api-methods">
        <div className="title">
          <span>Kyla AI API Available Methods</span>
        </div>
        <div className="methods">
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretDownFilled rotate={isActive ? 180 : 0} />
            )}
            expandIconPosition="end"
          >
            {methodsandProcedures.map((item, index) => (
              <Panel header={item.method} style={customPanelStyle}>
                <p className="sol">{item.procedure}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default API;
