import { message, Table } from "antd";
import { ArrowSquareRight } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminUsers } from "../../../API/Admin";
import "./style.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await adminUsers();
      if (Math.floor(res?.status / 100) === 2) {
        setUsers(res?.data);
      } else {
        message.error("Error fetching users");
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="users">
      <div className="page-title">
        <span>Users</span>
      </div>

      <div className="table-container">
        <Table
          pagination={{ position: ["bottomCenter"] }}
          dataSource={users}
          loading={loading}
        >
          <Table.Column title="User Name" dataIndex="name" key="name" />
          <Table.Column
            title="User Email"
            dataIndex="email"
            key="email"
            render={(url) => (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            )}
          />

          <Table.Column
            title="Date Joined"
            dataIndex="joined"
            key="date"
            render={(time) => {
              return new Date(time).toLocaleDateString();
            }}
          />
          <Table.Column
            title="Subscription Plan"
            dataIndex="plan"
            key="plan"
            render={(plan) => {
              return plan ? plan : "-";
            }}
          />

          <Table.Column
            width={160}
            title="Actions"
            key="actions"
            render={(text, record, index) => (
              <span style={{ fontSize: "24px", display: "flex", gap: "25px" }}>
                <Link to={`/user/${record.id}`}>
                  <ArrowSquareRight variant="Bold" color="#073F73" />
                </Link>
              </span>
            )}
          />
        </Table>
      </div>
    </div>
  );
};

export default Users;
