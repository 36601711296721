import { useEffect, useState } from "react";
import AppRoutes from "./Routes/AppRoutes";
import { ConfigProvider } from "antd";
import "./App.css";
import updateUserStates, {
  getRefreshToken,
  handleLogout,
} from "./Utils/UpdateUsersState";
import { decryptText, encryptText } from "./Utils/Encryption";
import { refresh } from "./API/Auth";
import axios from "axios";
import { UserContext } from "./Utils/Context/userContext";

const localTheme = localStorage.getItem("theme");
const themeValue =
  localTheme === "dark" || localTheme === "light" ? localTheme : "dark";

function App() {
  const [mode, setMode] = useState(themeValue);
  const [userData, setUserData] = useState({
    role: "",
    verified: "",
    id: "",
    email: "",
    name: "",
    fa: "",
    settings: false,
  });
  const [userToken, setUserToken] = useState({ access: "", refresh: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (mode === "dark") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  });

  const refreshAccessToken = async (token) => {
    let myRefreshToken = token?.refresh;
    if (!myRefreshToken) {
      myRefreshToken = getRefreshToken();
    }
    const res = await refresh(myRefreshToken);
    if (res?.status === 200) {
      const localStorageItems = localStorage;
      if (localStorageItems.length !== 0) {
        for (let key in localStorageItems) {
          if (localStorageItems[key] && decryptText(key) === "token") {
            localStorage.removeItem(key);
          }
        }
      }
      localStorage.setItem(
        encryptText("token"),
        encryptText(
          JSON.stringify({
            access: res?.data?.access,
            refresh: res?.data?.refresh,
          })
        )
      );
      updateUserStates(setUserData, setUserToken);
      return res?.data?.access;
    } else {
      handleLogout(setUserData, setUserToken);
      window.location.href = "/login";
      return null;
    }
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error?.response?.status === 401 &&
        error.config.url !==
          `${process.env.REACT_APP_BACKEND_URL}/token/refresh/` &&
        error.config.url !== `${process.env.REACT_APP_BACKEND_URL}/token/`
      ) {
        const newToken = await refreshAccessToken();

        if (newToken) {
          error.config.headers["Authorization"] = "Bearer " + newToken;
          return axios(error.config);
        } else {
          handleLogout(setUserData, setUserToken);
          window.location.href = "/login";
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const initializeUserState = async () => {
      setLoading(true);
      const updatedStates = updateUserStates(setUserData, setUserToken);
      if (
        updatedStates?.token?.access &&
        updatedStates?.token?.refresh &&
        loading
      ) {
        await refreshAccessToken(updatedStates.token);
      }
      setLoading(false);
    };

    if (
      userData?.role === "" &&
      userToken?.access === "" &&
      userToken?.refresh === "" &&
      loading
    ) {
      initializeUserState();
    } else {
      setLoading(false);
    }
  });

  return (
    <UserContext.Provider
      value={{ userData, setUserData, userToken, setUserToken }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: mode === "light" ? "#50a5f1" : "#073f73",
            borderRadius: 2,
            boxShadow: "none",
          },
          components: {
            Input: {
              paddingBlock: 8,
              paddingInline: 12,
            },
          },
        }}
      >
        <div className={mode === "dark" ? "App dark-mode" : "App"}>
          {!loading && <AppRoutes mode={mode} setMode={setMode} />}
        </div>
      </ConfigProvider>
    </UserContext.Provider>
  );
}

export default App;
