import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Button, Form, Input, message, Select } from "antd";
import { changePassword, editUser, getUser } from "../../../API/User";
import { ClipLoader } from "react-spinners";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { handleUpdateUser } from "../../../Utils/UpdateUsersState";
import { UserContext } from "../../../Utils/Context/userContext";
const { Option } = Select;

const Settings = ({ mode, setMode }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState(null);
  const { setUserData, userData } = useContext(UserContext);
  const [form] = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleThemeChange = (value) => {
    setMode(value);
    localStorage.setItem("theme", value);
  };

  const fetchUser = async () => {
    setLoading(true);
    const res = await getUser();
    if (Math.floor(res?.status / 100) === 2) {
      setData(res?.data);
      handleUpdateUser(setUserData, { ...userData, name: res?.data?.name });
    } else {
      message.error("Error fetching user");
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
    };
    fetchData();
  }, []);

  const handlePassword = async (values) => {
    const res = await changePassword(values);
    if (Math.floor(res?.status / 100) === 2) {
      message.success("Password updated successfully!");
      form.resetFields();
    } else {
      message.error("Error updating password!");
    }
  };

  const handleUserUpdate = async (values) => {
    setLoading(true);
    const res = await editUser(values);
    if (Math.floor(res?.status / 100) === 2) {
      message.success("User updated successfully!");
      await fetchUser();
    } else {
      message.error("Error fetching user");
    }
    setLoading(false);
  };

  return (
    <div className="settings">
      <div className="page-title">General Settings</div>
      <div className="settings-section">
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="loader-container"
          >
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
          </div>
        ) : (
          <Form
            initialValues={{ ...data, theme: mode }}
            layout="vertical"
            requiredMark={false}
            onFinish={handleUserUpdate}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter Email",
                },
              ]}
            >
              <Input placeholder="abc@example.com" type="email" disabled />
            </Form.Item>

            {/* <Form.Item
              label={alt_email_label}
              name="alt-email"
              extra={
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "22px",
                  }}
                >
                  You may want to specify a second email address in case their
                  primary email domain is down.
                </span>
              }
            >
              <Input placeholder="abc@example.com" type="email" />
            </Form.Item>

            <Form.Item label={mobile_label} name="mobile">
              <Input placeholder="+1 273 2382" type="" />
            </Form.Item> */}

            <Form.Item label="Theme" name="theme">
              <Select placeholder="Select a theme" onChange={handleThemeChange}>
                <Option value="light">Light</Option>
                <Option value="dark">Dark</Option>
              </Select>
            </Form.Item>
            <div className="settings-btn">
              <div />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={loading}
                  loading={loading}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
      <div className="settings-section-title">Change Password</div>
      <div className="settings-section">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handlePassword}
          form={form}
        >
          <Form.Item label="Existing Password" name="old_pass">
            <Input.Password
              className="password-input"
              required={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
          </Form.Item>

          <Form.Item label="New Password" name="pass">
            <Input.Password
              className="password-input"
              required={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
          </Form.Item>

          <Form.Item
            label="Re-enter Password"
            name="new_pass"
            rules={[
              {
                required: true,
                message: "Please re-enter the Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pass") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="password-input"
              prefix={<LockOutlined className="site-form-item-icon" />}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
          </Form.Item>
          <div className="settings-btn">
            {/* <Button danger ghost>
              Delete Account
            </Button> */}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loading}
                loading={loading}
              >
                Update Password
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Settings;
