import React, { useState } from "react";
import "./style.css";
import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../API/Auth";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const res = await forgotPassword(values);
    if (Math.floor(res?.status / 100) === 2) {
      message.success("Email Sent!");
      navigate("/login");
    } else {
      message.error("Invalid Email!");
    }
    setLoading(false);
  };

  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="login">
      <div className="login-card">
        <div className="title">
          <h1>Forgot Password</h1>
          <p>Welcome to Kyla AI</p>
        </div>
        <div className="form">
          <Form
            name="normal_login"
            className="login-form"
            requiredMark={false}
            onFinish={onFinish}
            {...formLayout}
          >
            <Form.Item
              label={<span className="username-label">Email</span>}
              name="email"
              rules={[
                {
                  message: "Please input your Email!",
                  required: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item>
              <span
                onClick={goToLogin}
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "22px",
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  paddingInlineStart: "10px",
                }}
              >
                Go to Login Instead
              </span>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loading}
                loading={loading}
              >
                Send Reset Password Link
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <span className="signup-link">Don't have an account? </span>
              <span onClick={goToSignup} className="signup-link underline">
                Sign Up
              </span>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
