import React, { useContext, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { Drawer, Layout } from "antd";
import "./style.css";
import Sidebar2 from "../Sidebar2";
import SidebarAdmin from "../SidebarAdmin";
import { UserContext } from "../../Utils/Context/userContext";

const { Content } = Layout;

const AppLayout = ({ contents }) => {
  const { userData } = useContext(UserContext);

  return (
    <div className="app-layout">
      {userData?.role === "user" ? (
        <Layout style={{ minHeight: "100vh" }}>
          <Drawer
            placement={"left"}
            width={200}
            styles={{ body: { padding: 0 } }}
          >
            {userData?.settings ? <Sidebar2 /> : <Sidebar />}
          </Drawer>
          {userData?.settings ? (
            <Sidebar2 breakpoint="md" />
          ) : (
            <Sidebar breakpoint="md" />
          )}
          <Layout className="site-layout">
            <Navbar />
            <Content className="content">{contents}</Content>
          </Layout>
        </Layout>
      ) : (
        <Layout style={{ minHeight: "100vh" }}>
          <Drawer
            placement={"left"}
            width={200}
            styles={{ body: { padding: 0 } }}
          >
            <SidebarAdmin breakpoint="md" />
          </Drawer>
          <SidebarAdmin breakpoint="md" />
          <Layout className="site-layout">
            <Navbar />
            <Content className="content">{contents}</Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default AppLayout;
