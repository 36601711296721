const errorMessage = (obj) => {
  let message = "";
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const errorMsg = obj[key][0];
      const formattedMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
      message = message ? `${message} ${formattedMsg}` : formattedMsg;
    }
  }
  return message;
};

export default errorMessage;
