import { Button, Form, Input, Modal, Upload, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { enable2fa, verify2fa } from "../../../../API/Auth";
import { handleUpdateUser } from "../../../../Utils/UpdateUsersState";
import { UserContext } from "../../../../Utils/Context/userContext";

const QrModal = ({ visible, setVisible, setVerificationMethod }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const inputsRef = useRef([]);
  const { setUserData, userData, setUserToken } = useContext(UserContext);
  const [qrImage, setQrImage] = useState("");

  useEffect(() => {
    const func = async () => {
      const res = await enable2fa();
      if (Math.floor(res?.status / 100) === 2) {
        setQrImage(res?.data?.qr_url);
      } else {
        message.error("Unable to get QrCode!");
      }
    };
    func();
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish();
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (value.length > 1) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const onFinish = async () => {
    const code = verificationCode.join("");
    const res = await verify2fa(code);
    console.log(res);
    if (Math.floor(res?.status / 100) === 2) {
      message.success(res?.data?.detail);
      setVerificationMethod("enable");
      setVisible(false)
      handleUpdateUser(setUserData, { ...userData, fa: false });
    } else {
      message?.error(res?.response?.data?.detail);
    }
  };

  return (
    <Modal
      className="qr-modal"
      title="2FA QrCode"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Enable 2FA"
      okButtonProps={{ loading: loading }}
    >
      <div className="step-text-box">
        <div className="step-text">
          <span style={{ fontWeight: "bold" }}>Step 1</span>: Scan the below QR
          Code with your favorite authenticator app e.g Google Authenticator
        </div>
      </div>
      <div className="qr-box">
        <img className="qr-image" src={qrImage} alt="QrCode Authenticator" />
      </div>
      <div className="step-text-box">
        <div className="step-text">
          <span style={{ fontWeight: "bold" }}>Step 2</span>: Enter the OTP code
          you see on the authenticator app
        </div>
      </div>
      <Form
        name="normal_verification"
        className="verification-form"
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="verificationCode"
          rules={[
            {
              message: "Please input the verification code!",
              required: true,
            },
          ]}
        >
          <div className="qr-modal-code-input">
            {Array.from({ length: 6 }).map((_, index) => (
              <Input
                key={index}
                ref={(el) => (inputsRef.current[index] = el)}
                maxLength={1}
                onChange={(event) => handleInputChange(index, event)}
                onKeyDown={(event) => handleKeyDown(index, event)}
                className="qr-modal-code-input-item"
              />
            ))}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QrModal;
