import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Pages/Public/Login/Login";
import Signup from "../Pages/Public/Signup/Signup";
import Subscription from "../Pages/Public/Subscription/Subscription";
import ForgotPassword from "../Pages/Public/ForgotPassword/ForgotPassword";
import PasswordReset from "../Pages/Public/PasswordReset/PasswordReset";
import VerifyNow from "../Pages/Public/VerifyNow/VerifyNow";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:uuid" element={<PasswordReset />} />
      <Route path="/verify/:uuid" element={<VerifyNow />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default PublicRoutes;
