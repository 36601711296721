import React, { useState } from "react";
import { Input, List, Empty } from "antd";
import MessageIcon from "../../../../Assets/Icons/message-text.png";
import "./style.css";
import { Send2 } from "iconsax-react";

const Debug = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleSendMessage = () => {
    if (inputValue.trim() !== "") {
      setMessages([...messages, { sender: "User", text: inputValue }]);
      setInputValue("");

      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "Kyla AI",
            text: "With your current package you can use 100 AI-Prompts a month.",
          },
        ]);
      }, 1000);
    }
  };

  return (
    <div className="debug-container">
      {messages.length === 0 ? (
        <div className="empty-container">
          <Empty
            description={
              <span className="have-ques">
                Have a question? <br /> Ask away!
              </span>
            }
            image={
              <img
                src={MessageIcon}
                alt="Ask away"
                style={{ width: 60, height: 60 }}
              />
            }
            imageStyle={{ height: 60 }}
          />
        </div>
      ) : (
        <List
          className="message-list"
          dataSource={messages}
          renderItem={(item) => (
            <List.Item
              className={`message-item ${
                item.sender === "Kyla AI" ? "ai" : "user"
              }`}
            >
              <div className="message-sender">{item.sender}</div>
              <div className="message-text">{item.text}</div>
            </List.Item>
          )}
        />
      )}
      <Input
        className="message-input"
        placeholder="Ask your question..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={handleSendMessage}
        suffix={
          <Send2
            onClick={handleSendMessage}
            variant="Bold"
            size="32"
            color="#073F73"
          />
        }
      />
    </div>
  );
};

export default Debug;
