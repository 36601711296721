import {
  Checkbox,
  Form,
  Input,
  Modal,
  Upload,
  message,
  notification,
} from "antd";
import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import "./style.css";
import {
  bulkUploadDomains,
  createDomain,
  getDomains,
} from "../../../../API/Domain";
import sampleFile from "../../../../Assets/Files/sample.xlsx";

const { Dragger } = Upload;

const AddDomainModal = ({
  folderId,
  visible,
  setVisible,
  setDomains,
  email,
}) => {
  const [loading, setLoading] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();

  const fetchData = async () => {
    setLoading(true);
    const res = await getDomains(folderId, email);

    if (Math.floor(res?.status) / 100 === 2) {
      setDomains(res?.data);
    }
    setLoading(false);
  };

  const handleCreateDomain = async (name, url) => {
    setLoading(true);
    try {
      const res = await createDomain(folderId, name, url, email);
      if (Math.floor(res?.status / 100) === 2) {
        message.success(`${email ? "Email" : "Domain"} created successfully!`);
        setDomains(res?.data);
      } else {
        message.error(`Failed to create ${email ? "email" : "domain"}.`);
      }
    } catch (error) {
      console.error("Error creating domain:", error);
      message.error(`Error creating ${email ? "email" : "domain"}.`);
    }
    setVisible(false);
    setLoading(false);
  };

  const handleBulkAdd = async () => {
    try {
      setLoading(true);

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const res = await bulkUploadDomains(formData, email, folderId);

        if (Math.floor(res.status / 100) === 2) {
          message.success(
            `${email ? "Emails" : "Domains"} created successfully!`
          );
          fetchData();
        } else {
          message.error(`Failed to Add ${email ? "emails" : "domains"}`);
        }
      } else {
        notification.error({
          message: "Invalid File",
          description: "Kindly upload the file for Bulk Upload",
        });
      }
    } catch (error) {
      console.error("Error creating club:", error);
    }
    setVisible(false);
    setLoading(false);
  };

  const handleOk = () => {
    if (bulkUpload) {
      handleBulkAdd();
    } else {
      form
        .validateFields()
        .then((values) => {
          let url = values?.domainUrl;
          if (email) {
            url = values?.domainUrl?.split("@")[1];
          }

          handleCreateDomain(values?.domainName, url);
          form.resetFields();
          setBulkUpload(false);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setBulkUpload(false);
    form.resetFields();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleCheckboxChange = (e) => {
    setBulkUpload(e.target.checked);
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".xlsx, .csv, .txt",
    onChange(info) {
      const { status } = info.file;
      console.log("File status:", status);
      if (info.file.status !== "uploading") {
        console.log("info", info);
        console.log("info.file:", info?.file);
        setFile(info?.file?.originFileObj);
        console.log("info.fileList", info?.fileList);

        message.success(`${info.file.name} file uploaded successfully`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Modal
      className="domain-modal"
      title={`Add a ${email ? "Email" : "Domain"}`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={`Add ${email ? "Email" : "Domain"}`}
      okButtonProps={{ loading: loading }}
    >
      <Form form={form} layout="vertical" name="domainForm">
        <Form.Item
          name="domainName"
          label={`${email ? "Email" : "Domain"} Name`}
          rules={[
            {
              required: !bulkUpload,
              message: `Please input the ${email ? "email" : "domain"} name!`,
            },
          ]}
        >
          <Input
            placeholder={`Enter ${email ? "email" : "domain"} name`}
            disabled={bulkUpload}
          />
        </Form.Item>
        <Form.Item
          name="domainUrl"
          label={`${email ? "Email" : "Domain Url"}`}
          rules={[
            {
              required: !bulkUpload,
              message: `Please input the ${email ? "email" : "Domain Url"}!`,
            },

            email
              ? { type: "email", message: "Please enter a valid email!" }
              : null,
          ]}
        >
          <Input
            placeholder={`Enter ${email ? "email" : "domain url"}`}
            disabled={bulkUpload}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={handleCheckboxChange}>
            Perform Bulk Upload
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="upload"
          label={`Upload ${email ? "Email" : "Domain"}`}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: bulkUpload, message: "Please upload a file!" }]}
        >
          <Dragger disabled={!bulkUpload} {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to upload</p>
            <p className="ant-upload-hint">
              Please ensure the file format is either .csv or .xlsx or .txt
            </p>
          </Dragger>
        </Form.Item>
        <a href={sampleFile} download="sample.xlsx">
          Download Sample File
        </a>
      </Form>
    </Modal>
  );
};

export default AddDomainModal;
