import { Button, Popconfirm, Table } from "antd";
import { ArrowSquareRight, Trash } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";

const Subscriptions = () => {
  const [subs, setSubs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = [
        { id: 1, plan: "Basic", status: "active", price: "$9.99" },
        { id: 2, plan: "Standard", status: "inactive", price: "$14.99" },
        { id: 3, plan: "Premium", status: "active", price: "$19.99" },
        { id: 4, plan: "Enterprise", status: "inactive", price: "$29.99" },
        { id: 5, plan: "Student", status: "active", price: "$4.99" },
      ];

      setSubs(data);
    };

    fetchData();
  }, []);

  const handleAddSub = () => {
    navigate("/add-new-subscription");
  };

  const handleDelete = async (id) => {
    console.log(id, "Delete");
  };

  return (
    <div className="subs">
      <div className="page-title">
        <span>Subscriptions</span>
        <Button
          onClick={handleAddSub}
          style={{ boxShadow: "none" }}
          type="primary"
          icon={<PlusOutlined />}
        >
          Add New
        </Button>
      </div>

      <div className="table-container">
        <Table pagination={false} dataSource={subs}>
          <Table.Column title="Plan Name" dataIndex="plan" key="plan" />
          <Table.Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record, index) => (
              <span
                className={
                  record.status === "active"
                    ? "status-active"
                    : "status-inactive"
                }
              >
                {record.status}
              </span>
            )}
          />

          <Table.Column title="Price" dataIndex="price" key="price" />
          <Table.Column
            width={160}
            title="Actions"
            key="actions"
            render={(text, record, index) => (
              <span style={{ fontSize: "24px", display: "flex", gap: "25px" }}>
                <Popconfirm
                  title="Delete Plan"
                  description="Are you sure you want to delete?"
                  onConfirm={() => handleDelete(record?.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Link>
                    <Trash variant="Bold" color="#FF4D4F" />
                  </Link>
                </Popconfirm>
                <Link to={`/subscription/${record.id}`}>
                  <ArrowSquareRight variant="Bold" color="#073F73" />
                </Link>
              </span>
            )}
          />
        </Table>
      </div>
    </div>
  );
};

export default Subscriptions;
