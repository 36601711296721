import React, { useContext } from "react";
import profileImage from "../../Assets/Images/Display Picture.png";
import "./style.css";
import { Popconfirm, message } from "antd";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utils/UpdateUsersState";
import { UserContext } from "../../Utils/Context/userContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { userData, setUserData, setUserToken } = useContext(UserContext);

  const confirm = () => {
    try {
      handleLogout(setUserData, setUserToken);
      navigate("/");
      message.success("Logged Out!");
    } catch (e) {
      message.success("Error while Logging out");
    }
  };

  return (
    <div className="navbar">
      <Popconfirm
        title="Logout from Application"
        description="Are you sure you want to logout?"
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
        <div className="profile-container">
          <div className="profile-details">
            <span className="name">{userData?.name}</span>
            <span className="email">{userData?.email}</span>
          </div>
          <div className="profile-img">
            <img
              src={profileImage}
              alt="Profile Img"
              className="profile-photo"
            />
          </div>
        </div>
      </Popconfirm>
    </div>
  );
};

export default Navbar;
