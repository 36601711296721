import React from "react";
import { Button, Table, Tooltip, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import "./style.css";
import { ClipLoader } from "react-spinners";

const Records = ({ loading, recordsData }) => {
  const copyRow = (rowData) => {
    const copyText = [rowData.field, rowData.detail].join("\t");
    navigator.clipboard.writeText(copyText);
    message.success("Row copied to clipboard!");
  };

  const copyTable = () => {
    const copyText = recordsData
      .map((row) => [row.field, row.detail].join("\t"))
      .join("\n");
    navigator.clipboard.writeText(copyText);
    message.success("Table copied to clipboard!");
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="loader-container"
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  return (
    <div className="records-wrap">
      <div className="copy-btn">
        <Button icon={<CopyOutlined />} type="primary" onClick={copyTable}>
          Copy Table
        </Button>
      </div>
      <div className="records">
        <Table pagination={false} dataSource={recordsData}>
          <Table.Column
            width={160}
            title="Field"
            dataIndex="field"
            key="field"
          />

          <Table.Column title="Details" dataIndex="detail" key="detail" />
          <Table.Column
            title="Copy"
            key="copy"
            render={(_, rowData) => (
              <Tooltip title="Copy">
                <Button
                  icon={<CopyOutlined />}
                  type="link"
                  onClick={() => copyRow(rowData)}
                />
              </Tooltip>
            )}
          />
        </Table>
      </div>
    </div>
  );
};

export default Records;
