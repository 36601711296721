import React from 'react';
import "./style.css";
import { CheckCircleOutlined } from '@ant-design/icons';

const Subscription = () => {
  const plans = [
    {
      title: "Free Version",
      price: "$0/Month",
      features: [
        "Single domain lookup.",
        "Record Scanning.",
        "Supports a DNS records lookup shown in the UI.",
        "Allows unsubscribed users to perform a single domain lookup.",
        "This function can be embedded into any page created on the platform or third-party websites.",
        "Displays DNS Lookup results with call-to-action buttons for sign up."
      ]
    },
    {
      title: "DNS AI – Discovery",
      price: "$1.99/month",
      features: [
        "Updated scans.",
        "AI assistance",
        "Export options.",
        "Domain management for up to 250 domains.",
        "Supports up to 3 admin users (additional admins $1.99 each, up to 10 total).",
        "Ability to save up to 250 domains.",
        "Perform up to 1,000 DNS scans per month.",
        "Provides users with domain tables."
      ]
    },
    {
      title: "DNS AI – Navigator",
      price: "$2.99/month",
      features: [
        "Bulk reporting for up to 1,000 domains.",
        "Domain insights dashboard.",
        "Customizable filters.",
        "Supports up to 3 admin users (additional admins $2.99 each, up to 10 total).",
        "Ability to save up to 1,000 domains.",
        "Perform up to 20,000 DNS scans per month.",
        "Provides users with domain tables."
      ]
    },
    {
      title: "DNS AI – Voyager",
      price: "$3.99/month",
      features: [
        "Includes all features of 'DNS Discovery' and 'Navigator' tiers with enhancements for enterprise-scale management.",
        "Manage up to 50,000 domains.",
        "Manage up to 20,000 emails in prospecting view.",
        "Supports up to 3 admin users (additional admins $3.99 each, up to 10 total).",
        "Perform up to 100,000 scans per month.",
        "API integration support available."
      ]
    },
    {
      title: "DNS AI – Enterprise",
      price: "$4.99/month",
      features: [
        "Includes all features of 'Voyager' tier with adjustable limits for saved domains and email tables.",
        "Customization of individual plans through the Host Admin Console.",
        "Access to the primary domain database for comprehensive domain aggregation.",
        "Ideal for large-scale customers with extensive domain management needs."
      ]
    }
  ];
  

  return (
    <div className="pricing-container">
      <h2 className="pricing-title">Choose a <span className="highlight">Pricing Plan</span></h2>
      <p className="pricing-subtitle">Which Best Fits your needs.</p>
      <div className="pricing-cards">
        {plans.map((plan, index) => (
          <div className="pricing-card" key={index}>
            <div className="pricing-header">
              <h3>{plan.price}</h3>
              <h4>{plan.title}</h4>
            </div>
            <ul className="pricing-features">
              {plan.features.map((feature, i) => (
                <li key={i}><CheckCircleOutlined className="icon" /> {feature}</li>
              ))}
            </ul>
            <button className="pricing-button">Upgrade</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;
