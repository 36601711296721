import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Button, Form, message } from "antd";
import { handleLogout } from "../../../Utils/UpdateUsersState";
import { UserContext } from "../../../Utils/Context/userContext";
import { getVerification } from "../../../API/Auth";

const CheckEmail = () => {
  const { setUserData, setUserToken } = useContext(UserContext);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isRunning, setIsRunning] = useState(true);

  const logout = () => {
    try {
      handleLogout(setUserData, setUserToken);
      message.success("Logged Out!");
    } catch (e) {
      message.error("Error while Loging Out!");
    }
  };

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
      setTimeLeft("-");
    }

    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  const resendEmail = async () => {
    if (!isRunning) {
      const res = await getVerification();
      if (Math.floor(res?.status / 100) === 2) {
        message.success("Email Sent!");
        setIsRunning(true);
        setTimeLeft(60);
      } else {
        message.error("Unable to send email!!");
      }
    }
  };

  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <div className="login">
      <div className="login-card">
        <div className="title">
          <h1>Please Check your email for verification</h1>
          <p>Didn't get any email? Check Spam</p>
        </div>
        <div className="form">
          <Form
            name="normal_login"
            className="login-form"
            requiredMark={false}
            {...formLayout}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={logout}
              >
                Logout
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <span className="signup-link">Didn't receive the email? </span>
              <span onClick={resendEmail} className="signup-link underline">
                Resend Email
              </span>
              <span> ({timeLeft})</span>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;
